import type { AppProps } from 'next/app'

import Head from 'next/head'
import { appWithTranslation, Trans, useTranslation } from 'next-i18next'
import CookieConsent from 'react-cookie-consent'

import '../styles/index.css'
import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { SessionProvider, useSession } from 'next-auth/react'
import { inBrowser, initGlobalEnv } from '@goodee/env-lib/src/app-env'
import { TRPCWrapper } from '@goodee/trpc-user-lib/src/TRPCWrapperBackoffice'
import Header from '../components/Header'
import Tabs, { getTabByPath, Tab, tabs } from '../components/Tabs'

type AuthWrapperProps = {
  children: ReactNode
}

const AuthWrapper = ({ children }: AuthWrapperProps): ReactElement => {
  const router = useRouter()
  const { data: session, status } = useSession()

  useEffect(() => {
    if (status === 'loading') {
      return
    }
    if (!session) {
      router.replace('/api/auth/signin')
    }
  }, [session, status, router])

  /*
  if (session?.user?.email) {
    setUser({ username: session.user.email })
  }
  */

  if (status === 'loading' || !session) {
    return <h1>loading session</h1>
  }

  return <>{children}</>
}

const BackofficeApp = ({ Component, pageProps }: AppProps) => {
  const { t } = useTranslation()
  const router = useRouter()
  const [isInitialized, setIsInitialized] = useState(!inBrowser())
  if (inBrowser() && !isInitialized) {
    initGlobalEnv(pageProps.env)
    //init(getSentryNextOptions('backoffice-app', 'client'))
    setIsInitialized(true)
  }
  const [currentTab, setCurrentTab] = useState<Tab>()

  useEffect(() => {
    if (router.isReady) {
      const tab = getTabByPath(router.asPath)

      if (tab) {
        setCurrentTab(tab)
      }
    }
  }, [router, router.asPath])

  return (
    <>
      <Head>
        <title>Goodee - Backoffice</title>
        <meta name="description" content="Goodee" />
        <link rel="icon" href="/icon.svg" type="image/svg+xml" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/site.webmanifest" />
      </Head>
      <TRPCWrapper>
        <SessionProvider session={pageProps.session}>
          <AuthWrapper>
            <Header />
            <Tabs />
            <div className="justify-between items-center mx-auto max-w-screen-4xl p-4">
              <h1 className="container font-bold mb-6">{currentTab?.name}</h1>

              <Component {...pageProps} />
            </div>
          </AuthWrapper>
        </SessionProvider>
      </TRPCWrapper>
    </>
  )
}

export default appWithTranslation(BackofficeApp)

import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { useDraggable } from 'lib/draggable'

export type Tab = {
  name: string
  href: string
  subTabs?: Tab[]
}

export const tabs: Tab[] = [
  {
    name: 'Dashboard',
    href: '/',
  },
  {
    name: 'Bestellungen',
    href: '/orders',
    subTabs: [
      {
        name: 'ID Konvertieren',
        href: '/orders/convert',
      },
      {
        name: 'Deaktivieren',
        href: '/orders/deactivate',
      },
      {
        name: 'Geld zurückerstatten',
        href: '/orders/refund',
      },
    ],
  },
  {
    name: 'Support',
    href: '/support',
    subTabs: [
      {
        name: 'Editionsverfügung',
        href: '/support/edv',
      },
      {
        name: 'Worldline',
        href: '/support/worldline',
      },
    ],
  },
]

export const getTabByPath = (path: string) => {
  for (let tab of tabs) {
    if (tab.href === path) {
      return tab
    }

    if (tab.subTabs) {
      for (let subTab of tab.subTabs) {
        if (subTab.href === path) {
          return subTab
        }
      }
    }
  }
}

const Tabs = () => {
  const [selectedTab, setSelectedTab] = useState(tabs[0])
  const router = useRouter()

  const ref =
    useRef<HTMLUListElement>() as React.MutableRefObject<HTMLUListElement>
  const { events } = useDraggable(ref)

  useEffect(() => {
    const basePath = router.asPath.split('/')[1]
    const findTab = tabs.find(tab => tab.href === `/${basePath}`)

    if (findTab) {
      setSelectedTab(findTab)
    }
  }, [router.asPath])

  return (
    <>
      <div className="text-sm font-medium text-center text-gray-500 bg-violet-50">
        <div className="justify-between items-center mx-auto max-w-screen-4xl">
          <ul
            className="flex no-wrap overflow-x-scroll scrollbar-hide -mb-px whitespace-nowrap"
            {...events}
            ref={ref}
          >
            {tabs.map(tab => {
              return (
                <li className="mr-2" key={tab.href}>
                  {tab.subTabs ? (
                    <div
                      className={`group/item inline-block p-4 border-b-2 rounded-t-lg ${
                        selectedTab.href === tab.href
                          ? 'text-violet-600 border-violet-600 active'
                          : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                      }`}
                    >
                      <span>{tab.name}</span>

                      <div className="absolute z-50 group-hover/item:block hidden bg-violet-100 divide-y divide-gray-100 rounded-lg shadow w-44 ">
                        <ul
                          className="py-2 text-sm text-gray-700"
                          aria-labelledby="dropdownDefaultButton"
                        >
                          {tab.subTabs.map(subTab => {
                            return (
                              <li className="text-left" key={subTab.href}>
                                <Link
                                  href={subTab.href}
                                  className="block px-4 py-2 hover:bg-violet-200"
                                >
                                  {subTab.name}
                                </Link>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <Link
                      href={tab.href}
                      className={`inline-block p-4 border-b-2 rounded-t-lg ${
                        selectedTab.href === tab.href
                          ? 'text-violet-600 border-violet-600 active'
                          : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                      }`}
                    >
                      {tab.name}
                    </Link>
                  )}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </>
  )
}

export default Tabs

import React, { ReactElement, ReactNode, useState } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createTRPCReact } from '@trpc/react-query'
import { appRouter } from './trpcAppRouterBackoffice'
import { trpcClientConfig } from '@goodee/trpc-lib/src/config/trpcClientConfigBackoffice'

export const trpc = createTRPCReact<typeof appRouter>()

export const TRPCWrapper = ({
  children,
}: {
  children: ReactNode
}): ReactElement => {
  const [queryClient] = useState(() => new QueryClient())
  const [trpcClient] = useState(() => trpc.createClient(trpcClientConfig()))
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <>{children}</>
      </QueryClientProvider>
    </trpc.Provider>
  )
}

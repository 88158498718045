import { useSession } from 'next-auth/react'
import Image from 'next/image'
import Link from 'next/link'

const Header = () => {
  const { data: session } = useSession()

  return (
    <>
      <nav className="bg-violet-100 border-gray-200 ">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-4xl p-4">
          <Link href="/" className="flex items-center">
            <Image
              width={54}
              height={54}
              src="https://shop.goodee.ch/icon.svg"
              className="h-10 mr-3"
              alt="Goodee Logo"
            />
            <span className="text-xl font-semibold whitespace-nowrap text-gray-700">
              Backoffice
            </span>
          </Link>
          <div className="flex items-center">
            {session?.user && (
              <>
                <span className="mr-3 text-gray-700 hidden md:block">
                  {session?.user?.name}
                </span>
                <Image
                  width={40}
                  height={40}
                  src={session?.user?.image as string}
                  className="h-10 mr-6 rounded-full"
                  alt="User Image"
                />
              </>
            )}
          </div>
        </div>
      </nav>
    </>
  )
}

export default Header

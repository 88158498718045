import { httpBatchLink } from '@trpc/client'
import superjson from 'superjson'
import type { CreateNextContextOptions } from '@trpc/server/adapters/next'
import { getServerSession } from 'next-auth'
import { getAppEnv, getEnvVar } from '@goodee/env-lib/src/app-env'
import { NextAuthOptions } from 'next-auth'
import AzureAD from 'next-auth/providers/azure-ad'

export function getBaseUrl() {
  if (typeof window !== 'undefined') {
    // In the browser, we return a relative URL
    return ''
  }
  // When rendering on the server, we return an absolute URL
  if (getAppEnv() === 'qa') {
    return 'https://qa-backend.goodee.ch'
  } else if (getAppEnv() === 'production') {
    return 'https://backend.goodee.ch'
  }

  // assume localhost
  return `http://localhost:${process.env.PORT ?? 3001}`
}

export const trpcClientConfig = () => ({
  links: [
    httpBatchLink({
      url: `${getBaseUrl()}/api/trpc`,
    }),
  ],
  transformer: superjson,
})

export const createContext = async (opts: CreateNextContextOptions) => {
  const authOptions: NextAuthOptions = {
    providers: [
      AzureAD({
        clientId: getEnvVar('BACKOFFICE_APP_AZURE_CLIENT_ID'),
        clientSecret: getEnvVar('BACKOFFICE_APP_AZURE_CLIENT_SECRET'),
        tenantId: getEnvVar('BACKOFFICE_APP_AZURE_TENANT_ID'),
      }),
    ],
  }

  const session = await getServerSession(opts.req, opts.res, authOptions)

  return { session: session || {} }
}
